import {mapActions, mapGetters, mapMutations} from 'vuex'
import {isEmpty, random, cloneDeep} from 'lodash'

export default {
  beforeDestroy() {
    console.log('beforeDestroy')
    console.log('timerAnime', this.timerAnime)
   
    clearInterval(this.delayTimer)
    clearInterval(this.timerWalk)
    clearInterval(this.timerSigh)
    clearInterval(this.timerClap)
    clearInterval(this.timerDig)
    clearInterval(this.timerBoard)
    this.animation && this.animation.pause()
    this.animation2 && this.animation2.pause()
    this.animation3 && this.animation3.pause()
    this.delayTimer = null
    this.timerWalk = null
    this.timerSigh = null
    this.timerClap = null
    this.timerDig = null
    this.timerBoard = null
    this.audioList = []
    const myAudio = document.getElementById('myAudio')
    myAudio.pause()
    myAudio.src = null
    this.isWalk = false
  },
  computed: {
    ...mapGetters(['gameRouter', 'gameLoaded'])
  },
  methods: {
    ...mapMutations(['SET_GAME_LOADED']),
    ...mapActions(['getGamesFontList']),
    async initDataAndImages() {
      console.log(1, this.gameRouter)
      this.$loading.show('数据加载中。。。')
      await this.initData(false)
      console.log('this.gameLoaded[this.gameRouter]', this.gameLoaded[this.gameRouter])
      // if (!this.gameLoaded[this.gameRouter]) {
        // 没有加载过的加载 ，加载过的不加载

        if (['game1-1','game1-4','game1-6','game2-1','game2-3','game2-9','game3-7', 'game4-3'].includes(this.gameRouter)) {
          await this.preloadClapImages()
        }
        if (['game1-7'].includes(this.gameRouter)) {
          await this.preloadPrevImages()
          await this.preloadNextImages()
        }
        if (['game3-10'].includes(this.gameRouter)) {
          await this.preloadWorkImages0(0)
          // await this.preloadWorkImages1(1)
          // await this.preloadWorkImages2(2)
          // await this.preloadWorkImages3(3)
          // await this.preloadWorkImages4(4)
          // await this.preloadWorkImages5(5)
        } else if (['game4-15'].includes(this.gameRouter)) {
          await this.preloadTypeImages0(0)
          // await this.preloadTypeImages1(1)
          // await this.preloadTypeImages2(2)
          await this.preloadTypeImages3(3)
          // await this.preloadTypeImages4(4)
          // await this.preloadTypeImages5(5)
        } else {
          await this.preloadWorkAndDigImages()
          await this.preloadBoardImages()
        }
        
        // this.SET_GAME_LOADED({
        //   [this.gameRouter]: true
        // })
      // }
      this.$loading.hide()
    },
    preloadPrevImages() {
      return new Promise((resolve) => {
        if (this.clapImgLength) {
          for (let i = 1; i < this.clapImgLength+1; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/prev/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/prev/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.prevImgCount++
              console.log('prevImgCount', this.prevImgCount, this.prevImgCount)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.prevImgCount === this.clapImgLength) {
                // 加载完毕
                console.log('完毕', this.prevImgCount)
                resolve()
              }
            }
          }
        }
      })
    },
    preloadNextImages() {
      return new Promise((resolve) => {
        if (this.clapImgLength) {
          for (let i = 1; i < this.clapImgLength+1; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/next/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/next/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.nextImgCount++
              console.log('nextImgCount', this.nextImgCount, this.nextImgCount)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.nextImgCount === this.clapImgLength) {
                // 加载完毕
                console.log('完毕', this.nextImgCount)
                resolve()
              }
            }
          }
        }
      })
    },
    preloadBoardImages() {
      return new Promise((resolve) => {
        if (this.boardImgLength) {
          for (let i = 0; i < this.boardImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type0/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type0/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.boardImgCount++
              console.log('boardImgCount', this.boardImgCount, this.boardImgLength)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.boardImgCount === this.boardImgLength) {
                // 加载完毕
                console.log('完毕', this.boardImgCount)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
    },
    preloadClapImages() {
      return new Promise((resolve) => {
        if (this.clapImgLength) {
          for (let i = 0; i < this.clapImgLength - 1; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/clap/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/clap/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.clapImgCount++
              console.log('clapImgCount', this.clapImgCount)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.clapImgCount === this.clapImgLength - 1) {
                // 加载完毕
                console.log('完毕', this.clapImgCount)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
    },
    preloadWorkAndDigImages() {
      return new Promise((resolve) => {
        if (this.digImgLength) {
          for (let i = 0; i < this.digImgLength - 1; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.workImgCount++
              console.log('dig workImgCount', this.workImgCount, this.digImgLength - 1)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.workImgCount === this.digImgLength - 1) {
                // 加载完毕
                console.log('完毕', this.workImgCount)
                resolve()
              }
            }
          }
        } else if (this.workImgLength) {
          for (let i = 0; i < this.workImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.workImgCount++
              console.log('workImgCount', this.workImgCount, this.workImgLength)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.workImgCount === this.workImgLength-1) {
                // 加载完毕
                console.log('完毕', this.workImgCount)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadWorkImages0(index) {
      return new Promise((resolve) => {
        if (this.digImgLength) {
          for (let i = 0; i < this.digImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              console.log('index', index)
              // console.log('dig workImgCount', index, this.workImg0Count, this.digImgLength)
              console.log('dig workImgCount', this.workImg0count, this.digImgLength)
              this.workImg0count++
              // 计算图片加载的百分数，绑定到percent变量
              if(this.workImg0count === this.digImgLength) {
                // 加载完毕
                console.log('完毕', this.workImg0count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadWorkImages1(index) {
      return new Promise((resolve) => {
        if (this.digImgLength) {
          for (let i = 0; i < this.digImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              console.log('index', index)
              // console.log('dig workImgCount', index, this.workImg0Count, this.digImgLength)
              console.log('dig workImgCount', this.workImg1count, this.digImgLength)
              this.workImg1count++
              // 计算图片加载的百分数，绑定到percent变量
              if(this.workImg1count === this.digImgLength) {
                // 加载完毕
                console.log('完毕', this.workImg1count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadWorkImages2(index) {
      return new Promise((resolve) => {
        if (this.digImgLength) {
          for (let i = 0; i < this.digImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              console.log('index', index)
              // console.log('dig workImgCount', index, this.workImg0Count, this.digImgLength)
              console.log('dig workImgCount', this.workImg2count, this.digImgLength)
              this.workImg2count++
              // 计算图片加载的百分数，绑定到percent变量
              if(this.workImg2count === this.digImgLength) {
                // 加载完毕
                console.log('完毕', this.workImg2count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadWorkImages3(index) {
      return new Promise((resolve) => {
        if (this.digImgLength) {
          for (let i = 0; i < this.digImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              console.log('index', index)
              // console.log('dig workImgCount', index, this.workImg0Count, this.digImgLength)
              console.log('dig workImgCount', this.workImg3count, this.digImgLength)
              this.workImg3count++
              // 计算图片加载的百分数，绑定到percent变量
              if(this.workImg3count === this.digImgLength) {
                // 加载完毕
                console.log('完毕', this.workImg3count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadWorkImages4(index) {
      return new Promise((resolve) => {
        if (this.digImgLength) {
          for (let i = 0; i < this.digImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              console.log('index', index)
              // console.log('dig workImgCount', index, this.workImg0Count, this.digImgLength)
              console.log('dig workImgCount', this.workImg4count, this.digImgLength)
              this.workImg4count++
              // 计算图片加载的百分数，绑定到percent变量
              if(this.workImg4count === this.digImgLength) {
                // 加载完毕
                console.log('完毕', this.workImg4count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadWorkImages5(index) {
      return new Promise((resolve) => {
        if (this.digImgLength) {
          for (let i = 0; i < this.digImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/walk${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              console.log('index', index)
              // console.log('dig workImgCount', index, this.workImg0Count, this.digImgLength)
              console.log('dig workImgCount', this.workImg5count, this.digImgLength)
              this.workImg5count++
              // 计算图片加载的百分数，绑定到percent变量
              if(this.workImg5count === this.digImgLength) {
                // 加载完毕
                console.log('完毕', this.workImg5count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadTypeImages0(index) {
      return new Promise((resolve) => {
        console.log('boardImgLength', this.boardImgLength)
        if (this.boardImgLength) {
          for (let i = 0; i < this.boardImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.typeImg0count++
              console.log('board workImgCount', this.typeImg0count, this.boardImgLength)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.typeImg0count === this.boardImgLength) {
                // 加载完毕
                console.log('完毕', this.typeImg0count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadTypeImages1(index) {
      return new Promise((resolve) => {
        if (this.boardImgLength) {
          for (let i = 0; i < this.boardImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.typeImg1count++
              console.log('board workImgCount', this.typeImg1count, this.boardImgLength)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.typeImg1count === this.boardImgLength) {
                // 加载完毕
                console.log('完毕', this.typeImg1count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadTypeImages2(index) {
      return new Promise((resolve) => {
        if (this.boardImgLength) {
          for (let i = 0; i < this.boardImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.typeImg2count++
              console.log('board workImgCount', this.typeImg2count, this.boardImgLength)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.typeImg2count === this.boardImgLength) {
                // 加载完毕
                console.log('完毕', this.typeImg2count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadTypeImages3(index) {
      return new Promise((resolve) => {
        if (this.boardImgLength) {
          for (let i = 0; i < this.boardImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.typeImg3count++
              console.log('board workImgCount', this.typeImg3count, this.boardImgLength)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.typeImg3count === this.boardImgLength) {
                // 加载完毕
                console.log('完毕', this.typeImg3count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadTypeImages4(index) {
      return new Promise((resolve) => {
        if (this.boardImgLength) {
          for (let i = 0; i < this.boardImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.typeImg4count++
              console.log('board workImgCount', this.typeImg4count, this.boardImgLength)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.typeImg4count === this.boardImgLength) {
                // 加载完毕
                console.log('完毕', this.typeImg4count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    preloadTypeImages5(index) {
      return new Promise((resolve) => {
        if (this.boardImgLength) {
          for (let i = 0; i < this.boardImgLength; i++) {
            let image = new Image()
            console.log('加载', `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,)
            image.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/${this.gameRouter}/anime/type${index}/${i}.png`,
              // 图片加载
            image.onload = () => {
              this.typeImg5count++
              console.log('board workImgCount', this.typeImg5count, this.boardImgLength)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.typeImg5count === this.boardImgLength) {
                // 加载完毕
                console.log('完毕', this.typeImg5count)
                resolve()
              }
            }
          }
        } else {
          resolve()
        }
      })
      
    },
    async initData(loading = true) {
      loading && this.$loading.show('数据加载中。。。')
      this.currentWord = {}
      this.showWordList = []
      this.wordList = []
      const res = await this.getGamesFontList({
        gradeId: this.id,
        current: this.current,
        pagesize: 6
      })
      console.log('InitWordList', res)
      this.InitWordList = res.result
      this.wordList = res.result
      this.showWordList = res.result
      this.gameName = res.fontName
      this.current = res.pageNum
      this.pages = res.pages
      if (this.current === this.pages) {
        this.isLoop = true
      }
      this.isWalk = false
      this.getData()
      loading && this.$loading.hide()
    },
    startGameHandler(flag) {
      console.log('current', this.current)
      console.log('pages', this.pages)
      console.log('isLoop', this.isLoop)
      
      if (flag) {
        this.showStartGameIcon = false
        this.isLoop = false
      }
      if (this.isLoop==true && this.current==2) {
        this.startBtn = 'https://image.yuanyuanwenhua.com/literacy/static/img/allGame/btn-restart.png'
        this.moreName = `第${this.$route.query.index}课生字检测通过了，<br/>恭喜你！`
        this.showStartGameIcon = true
        console.log('再来一次')
      } else {
        this.startBtn = 'https://image.yuanyuanwenhua.com/literacy/static/img/allGame/btn-start.png'
        this.moreName = `第${this.$route.query.index}课生字检测`
        this.showWordList = this.showWordList.map(item => {
          return {
            ...item,
            showFirst: true,
            showIcon: true
          }
        })
        this.audioList= [this.clickAudio, this.currentWord.voice]
        this.playAudio()
      }
    },
    playAudio () {
      this.isWalk = true
      const myAudio = document.getElementById('myAudio')
      myAudio.src=this.audioList[0]
      myAudio.play()
      let index = 0
      myAudio.onended = () => {
        console.log(index, this.audioList)
        if(index < this.audioList.length - 1){
          myAudio.src=this.audioList[index+1];
          myAudio.play()
          index++;
        } else {
          this.audioList = []
          this.isWalk = false
        }
      };
    },
    playClickAudio () {
      document.getElementById('clickAudio').play()
    },
    playFailAudio () {
      this.isWalk = true
      const failAudio = document.getElementById('failAudio')
      failAudio.play()
      failAudio.onended = () => {
        this.audioList = [this.currentWord.voice]
        this.playAudio()
      };
    },
    getData(wait) {
      console.log('get', this.wordList)
      if (!isEmpty(this.wordList)) {
        const index = random(this.wordList.length-1)
        this.currentWord = cloneDeep(this.wordList[index])
        this.wordList = this.wordList.filter(item => item.id !== this.currentWord.id)
        console.log('currentWord', this.currentWord)
        console.log('wordList', this.wordList)
        if (wait) {
           // 下一个字
          // this.isWalk = true
          this.audioList = [this.currentWord.voice]
          this.playAudio()
        } else {
          // 首次加载就不播放了，不然报错会影响其他按钮的声音也播放不出来
          // this.audioList.push(this.currentWord.voice)
          // this.playAudio()
        }
      } else {
        // 这里是最后一个字没有喝彩声
        // this.audioList.push(this.hecaiAudio)
        // this.playAudio()
        // setTimeout(() => {
          // 全部完成 播放胜利音乐 不用播放胜利音乐了
          // this.audioList = [this.successAudio]
          // this.playAudio()
          // 清空当前数据
          this.isWalk = false
          this.currentWord = {}
          this.reverseHandler()
        // }, 3000)
      }
    },
    // 播放文字startHander
    startHandler() {
      if (!this.isWalk) {
        this.audioList = [this.clickAudio, this.currentWord.voice]
        this.playAudio()
      }
      
    },
    backHandler() {
      this.audioList = [this.clickAudio]
      this.playAudio()
      this.$router.back()
      window.wx.miniProgram.navigateBack()
      // window.wx.miniProgram.navigateBack()
    }
  },
  data() {
    return {
      startBtn: 'https://image.yuanyuanwenhua.com/literacy/static/img/allGame/btn-start.png',
      moreName: `第${this.$route.query.index}课生字检测`,
      isLoop: false,
      pages: 1,
      delayTimer: null,
      current: 1,
      boardImgCount: 0,
      workImgCount: 0,
      clapImgCount: 0,
      nextImgCount: 0,
      prevImgCount: 0,
      typeImg0count: 0,
      typeImg1count: 0,
      typeImg2count: 0,
      typeImg3count: 0,
      typeImg4count: 0,
      typeImg5count: 0,
      workImg0count: 0,
      workImg1count: 0,
      workImg2count: 0,
      workImg3count: 0,
      workImg4count: 0,
      workImg5count: 0,
      animation: null,
      animation2: null,
      animation3: null,
      height: document.documentElement.clientHeight,
      animeTypes: 0, // 动画挖宝成功种类
      timerWalk: null,
      timerSigh: null,
      timerClap: null,
      timerDig: null,
      timerBoard: null,
      InitWordList: [],
      gradeId: this.$route.query.gradeId,
      wordList: [],
      showWordList: [],
      currentWord: {},
      audioList: [],
      hecaiAudio: require(`@/assets/sounds/hecai.mp3`),
      clickAudio: require(`@/assets/sounds/click.mp3`),
      failAudio: require(`@/assets/sounds/fail.mp3`),
      successAudio: require(`@/assets/sounds/success5.mp3`),
      showStartGameIcon: true,
      personX: null,
      personY: null,
      partnerX: null,
      partnerY: null,
      isWalk: false,
      isVertical: true // 是否旋转屏幕 默认是旋转了
    }
  },
  created () {
    this.initDataAndImages()
  },
  async mounted () {
    if(document.querySelector('#app section').style.transform === 'rotate(0deg)') {
       // 横屏  word-container
      document.querySelector('.home').classList.add('home-horizontal')

      this.isVertical = false
    } else {
      this.isVertical = true
      document.querySelector('.home').className = 'home'
    }
    // console.log('screen', typeof document.querySelector('#app section').style.transform)
    // 如果是 rotate(90deg) 说明屏幕旋转了 isVertical true
    
    
    // this.personX = document.getElementById('person').getBoundingClientRect().x
    // this.personY = document.getElementById('person').getBoundingClientRect().y
    // this.partnerX = document.getElementById('partner').getBoundingClientRect().x
    // this.partnerY = document.getElementById('partner').getBoundingClientRect().y
    console.log('personX', this.personX)
    console.log('personY', this.personY)
    console.log('WeixinJSBridge', window.WeixinJSBridge)
    if (window.WeixinJSBridge){
      window.WeixinJSBridge.on('onPageStateChange', (res) => {
        console.log('res is active', res.active)
        this.isWalk = false
      })
    }
  }
}